@font-face {
    font-family: 'GalanoClassic';
    src: url('font/reg.woff2') format('woff2'), url('font/reg.woff') format('woff');
    font-weight: 400;
}
@font-face {
    font-family: 'GalanoClassic';
    src: url('font/med.woff2') format('woff2'), url('font/med.woff') format('woff');
    font-weight: 500;
}
@font-face {
    font-family: 'GalanoClassic';
    src: url('font/semi.woff2') format('woff2'), url('font/semi.woff') format('woff');
    font-weight: 600;
}

$font: 'GalanoClassic';

/* colours */
$primary: #48a7b5; //blue
$secondary: #8ac04b; //green
$tertiary: #f4847b;  //pink

$text: #58595b;
$link-hover: all 0.3s ease-in-out;

@mixin gradientBg(){
    background-color: $secondary;
    background: -webkit-linear-gradient(left, $secondary, $primary);
    background: linear-gradient(to right, $secondary, $primary);
}